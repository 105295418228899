import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
export default function Solution() {

    const dataList = [{ sequence: '1.', fname: 'Ads', sname: '', discription: 'Promote your movies, web-series, and music videos with targeted Google Ads and Meta Ads, maximizing visibility and engagement across platforms. ' },
    { sequence: '2.', fname: 'Reels', sname: '', discription: 'Boost your promotions with dynamic Reels on Instagram, YouTube and Facebook, designed to captivate viewers and generate excitement.' },
    { sequence: '3.', fname: 'PR ', sname: '', discription: 'Elevate your content with strategic PR campaigns, securing news features and media coverage to create widespread buzz. ' },
    { sequence: '4.', fname: 'IMDB', sname: '', discription: 'Optimize your IMDb listings for movies, web-series, and music videos, improving visibility and credibility to attract more viewers.' },]

    return (
        <Box className=' commonBg marginTop5'>
            <Box className='container'>
                <Box className='solution-container'>
                    <Typography>Our Solution</Typography>
                    <Typography variant='span' pt={2}>Amplify Your Audience Reach with GrooveNexus Solutions: Tailored Strategies for Music Success</Typography>
                </Box>
                <Box className='marginTop3'>
                    <Grid container columns={{ xs: 12, sm: 6, md: 12 }}>
                        {
                            dataList.map((d, i) => (
                                <Grid key={i} item xs={12} sm={6} md={3} >
                                    <Box className='solution-grid box-ad-style'>
                                        <Typography variant='span' className='textgradient'>{d.sequence}</Typography>
                                        <Box>
                                            <Typography >{d.fname}</Typography>
                                            <Typography >{d.sname}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className='marginTop3'>
                                        <Typography className='banner-subtext font16'>
                                            {d.discription}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Box>

        </Box>
    );
}
