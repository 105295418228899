import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box, Button, DialogContent, DialogContentText, FormControl, IconButton, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';

export default function ContactUs({ open, setOpen, reset }) {
    const [stateValue, setStateValue] = React.useState(reset)
    const [disableValue, setDisableValue] = React.useState(true)
    const [emailValid, setEmailValid] = useState(true);
    const smScreen = useMediaQuery('(max-width:600px)');

    const handleClose = (value) => {
        setOpen(false);
        setStateValue(false)
    };

    const styling = {
        margin: '10px 0px'
    }

    function handleChange(event) {
        const { name, value } = event.target;
        const fields = ['name', 'emailid', 'contact', 'link', 'enquiry'];

        if (fields.includes(name)) {
            setDisableValue(false);
        }
        if (event.type == 'blur') {
            if (name.trim() === 'emailid') {
                const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                setEmailValid(emailPattern.test(value));
            }
        }
    }

    const handleSubmit = () => {
        setStateValue(true)
    }

    return (
        <Dialog fullWidth={true}
            maxWidth={'sm'} open={open}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '40px'
                }
            }}
        >
            {!stateValue ?
                <Box>
                    <Box sx={{ background: '#F6F5F5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '40px' }}>
                        <DialogTitle sx={{ fontSize: smScreen ? '20px' : '25px' }} > Contact Us
                            <Typography sx={{ fontSize: smScreen ? '12px' : '14px', width: smScreen ? '85%' : '100%' }} >  We’d love to hear from you! Write to us.</Typography>
                        </DialogTitle >
                        {/* <CloseIcon onClick={handleClose} sx={{ fontSize: '3rem', cursor: 'pointer' }} /> */}
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 30,
                                fontSize: '3rem'
                            }}
                        >
                            <CloseIcon sx={{ fontSize: '3rem', cursor: 'pointer' }}/>
                        </IconButton>
                    </Box>
                    <DialogContent>
                        <Box className='contact-us'>
                            <TextField
                                required
                                variant="standard"
                                sx={{ margin: styling.margin }}
                                fullWidth
                                id="outlined-required"
                                label="Name "
                                name="name "
                                rows={4}
                                onChange={(e) => handleChange(e)}

                            />
                            <TextField
                                required
                                variant="standard"
                                sx={{ margin: styling.margin }}
                                fullWidth
                                id="outlined-required"
                                label="Email id "
                                type='email'
                                name="emailid "
                                error={!emailValid}
                                helperText={!emailValid ? <Typography className='font14'>Please enter a valid email.</Typography> : ""}
                                rows={4}
                                onChange={(e) => handleChange(e)}
                                onBlur={handleChange}

                            />
                            <TextField
                                required
                                variant="standard"
                                sx={{ margin: styling.margin }}
                                fullWidth
                                id="outlined-required"
                                label="Phone Number "
                                type="number"
                                name="contact "
                                rows={4}
                                onChange={(e) => handleChange(e)}

                            />
                            <TextField
                                required
                                variant="standard"
                                sx={{ margin: styling.margin }}
                                fullWidth
                                id="outlined-required"
                                label="YT Channel link"
                                name="link "
                                rows={4}
                                onChange={(e) => handleChange(e)}

                            />
                            <TextField
                                required
                                variant="standard"
                                sx={{ margin: styling.margin }}
                                fullWidth
                                id="outlined-required"
                                label="Message/Enquiry"
                                name="enquiry "
                                rows={4}
                                onChange={(e) => handleChange(e)}

                            />
                        </Box>
                        <Box sx={{
                            margin: '15px 0px',
                            textAlign: 'right'
                        }}>
                            <Button onClick={handleSubmit} sx={{ width: '100px' }} className='header-btn font14'>Submit</Button>
                        </Box>
                    </DialogContent>
                </Box>
                :
                <DialogContent>
                    <DialogContentText paddingTop={2} paddingBottom={2} className='font18'>
                        Thank you for reaching out to us!
                        We appreciate your interest, we will get back to you soon.
                    </DialogContentText>
                    <Box sx={{
                        textAlign: 'right'
                    }}>
                        <Button onClick={handleClose} sx={{ width: '100px' }} className='header-btn font14'>Ok</Button>
                    </Box>
                </DialogContent>
            }
        </Dialog >
    );
}
