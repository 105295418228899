import * as React from 'react';
import Box from '@mui/material/Box';
import adImg from '../img/ad-img.png'
import Frame1 from '../img/Frame1.svg'
import Frame2 from '../img/Frame2.svg'
import Frame3 from '../img/Frame3.svg'
import Frame4 from '../img/Frame4.svg'
import { Button, Divider, Grid, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';

export default function AdsData() {
    const smScreen = useMediaQuery('(max-width:600px)');
    const dataList = [{ id: 1, img: Frame4, fname: 'Maximize', sname: 'Movie Trailer Reach  ', marginBottom: '30px' },
    { id: 2, img: Frame1, fname: 'Increase', sname: 'Your Visibility & Engagement ', marginBottom: '30px' },
    { id: 3, img: Frame2, fname: 'Supervised by', sname: 'Industry Experts', marginBottom: '30px' },
    { id: 4, img: Frame3, fname: 'Boost Your', sname: 'Movie with Multi-Channel Approch ', marginBottom: '' },]

    return (
        <Box className=' commonBg'>
            <Box className='ads-responsive ads-container container'>
                <Box>
                    <img src={adImg} alt='adImg' />
                </Box>
                <Box className='ad-head-text'>
                    <Typography mt={2} >Movie’s Marketing</Typography>
                    <Typography variant='span' pt={2}>Enhance your online presence with our expert promotional services.
                        We’re dedicated to maximizing your movie’s potential.</Typography>
                    <Grid className='channel-container marginTop3' container columns={{ xs: 12, sm: 12, md: 12 }}>
                        {
                            dataList.map((d, i) => (
                                <Grid sx={{ marginBottom: d.marginBottom, textAlign: smScreen ? 'center' : '' }} key={i} item xs={12} sm={6} md={6} >
                                    <Box >
                                        <img src={d.img} className='w-h-66' />
                                    </Box>
                                    <Typography variant='span'>{d.fname}</Typography>
                                    <Typography variant='span'>{d.sname}</Typography>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Box>
        </Box >
    );
}
