import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Divider, Grid, Typography } from '@mui/material';
import Sarvinarck from '../img/sliderimg/Sarvinarck.png'
import SwattrexMusic from '../img/sliderimg/SwattrexMusic.png'
import vivek from '../img/sliderimg/vivek.png'
import loy from '../img/sliderimg/loy.png'
import quotes from '../img/sliderimg/quotes.svg'
import { useMediaQuery } from '@mui/material';

export default function Review() {
    const smScreen = useMediaQuery('(max-width:600px)');
    const mdScreen = useMediaQuery('(max-width:768px)');
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [slidShow, setSlidShow] = React.useState([]);

    React.useEffect(() => {
        if (smScreen) {
            const newSlidShow = [{
                id: 1,
                artist: [{ label: 'Sarvinarck', detail: 'Music–Label', description: "I am thoroughly impressed by GrooveNexus’ YouTube Management and Google Ads Promotion services; they offered remarkable end-to-end solutions that greatly boosted my channel's growth and online presence for Sarvinarck Music. Best Wishes to the Team! ", slidimg: Sarvinarck }
                ]
            },
            {
                id: 2,
                artist: [{ label: 'Loy Armo', detail: 'Artist', description: "GrooveNexus Records is an amazing platform for emerging artists like me. They're incredibly supportive and encouraging. My experience with them was truly fantastic. They provide excellent opportunities for growth and exposure", slidimg: loy }]
            },
            {
                id: 3,
                artist: [{ label: 'Swattrex Agrawal', detail: 'Artist', description: "GrooveNexus is doing great work by uplifting underrated artists. 5 years back I started my musical journey with GrooveNexus and it played a vital role in my growth. Thank you GrooveNexus for supporting me since beginning.  ", slidimg: SwattrexMusic }]
            },
            {
                id: 2,
                artist: [
                    { label: 'Vivek Mishra', detail: 'Artist', description: 'Collaborating with GrooveNexus was fantastic. They offer great opportunities for growth and exposure. Overall, a wonderful experience working with them. Highly recommended to any artist looking to take their career to the next level! ', slidimg: vivek }]
            }]
            setSlidShow(newSlidShow);

        } else {
            const newSlidShow = [{
                id: 1,
                artist: [{ label: 'Sarvinarck', detail: 'Music–Label', description: "I am thoroughly impressed by GrooveNexus’ YouTube Management and Google Ads Promotion services; they offered remarkable end-to-end solutions that greatly boosted my channel's growth and online presence for Sarvinarck Music. Best Wishes to the Team! ", slidimg: Sarvinarck },
                { label: 'Loy Armo', detail: 'Artist', description: "GrooveNexus Records is an amazing platform for emerging artists like me. They're incredibly supportive and encouraging. My experience with them was truly fantastic. They provide excellent opportunities for growth and exposure", slidimg: loy }]
            },
            {
                id: 2,
                artist: [{ label: 'Swattrex Agrawal', detail: 'Artist', description: "GrooveNexus is doing great work by uplifting underrated artists. 5 years back I started my musical journey with GrooveNexus and it played a vital role in my growth. Thank you GrooveNexus for supporting me since beginning.  ", slidimg: SwattrexMusic },
                { label: 'Vivek Mishra', detail: 'Artist', description: 'Collaborating with GrooveNexus was fantastic. They offer great opportunities for growth and exposure. Overall, a wonderful experience working with them. Highly recommended to any artist looking to take their career to the next level! ', slidimg: vivek }]
            }]
            setSlidShow(newSlidShow);
        }

    }, [smScreen,mdScreen])


    React.useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % slidShow.length);
        }, 8000);  // Change slide every 3 seconds
        return () => clearInterval(interval);
    }, [slidShow.length]);

    const handleIndicatorClick = (index) => {
        setCurrentIndex(index);
    };

    return (
        <Box className='review '>
            <Box className='review-container'>
                <Typography>Reviews/Client Testimonials </Typography>
                <Typography variant='span' pt={2}>Unlock Your Potential: Choose Us for Transparent Growth and Unmatched Support.</Typography>
            </Box>
            <Box className="">
                <Box className="row">
                    <Box className="col-sm-12">
                        <Box id="myCarousel" className="carousel slide" data-ride="carousel">
                            <ol className="carousel-indicators">
                                {slidShow.map((slide, index) => (
                                    <li onClick={() => handleIndicatorClick(index)} key={index} data-target="#myCarousel" data-slide-to={index} className={index === currentIndex ? "active" : ""}></li>
                                ))}
                            </ol>
                            <Box className="carousel-inner">
                                {slidShow.map((slide, index) => (
                                    <Box key={index} className={`item ${index === currentIndex ? "active" : ""}`}>
                                        <Box className="row box-ad-style">
                                            {slide?.artist.map((slideData, i) => (
                                                <Box className="col-md-6 col-xs-12 col-sm-12 col-lg-6" key={i} >
                                                    <Box className="testimonial">
                                                        <Typography className='font18'><img className='quotemark' src={quotes} />{slideData.description}</Typography>
                                                    </Box>
                                                    <Box sx={{ marginTop: '24px' }}>
                                                        <Box className="">
                                                            <Box className="box-ad-style overview">
                                                                <img src={slideData.slidimg} alt="" />
                                                                <Box className="name font18"><b>{slideData.label}</b>
                                                                    <Typography className="details font14">{slideData.detail}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box >
    );
}