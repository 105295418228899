import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import logo from '../img/logo.svg'
import { Box, Button } from '@mui/material';
import ContactUs from './ContactUs';
import { useMediaQuery } from '@mui/material';

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function ElevateAppBar(props) {
    const [open, setOpen] = React.useState(false);
    const mdScreen = useMediaQuery('(max-width:768px)');
    const smScreen = useMediaQuery('(max-width:600px)');

    const handleClickOpen = () => {
        setOpen(true);
    };

    return (
        <React.Fragment>
            <ElevationScroll {...props}>
                <AppBar sx={{ backgroundColor: '#fff' }}>
                    <Toolbar sx={{ justifyContent: 'space-between', padding: smScreen ? '0px' : '0px 5rem' }}>
                        <img src={logo} style={{ width: smScreen ? "150px" : 'auto' }} />
                        <Box>
                            <a href='https://ads.groovenexus.com/'>
                                <Button sx={{
                                    color: '#000',
                                    border: '1px solid #ED3237',
                                    marginRight: '35px'
                                }} className='font14'>Login</Button>
                            </a>
                            <Button sx={{ display: smScreen ? 'none' : 'inline' }} onClick={handleClickOpen} className='header-btn font14'>Book a free call with us</Button>
                        </Box>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Toolbar />
            <ContactUs open={open} setOpen={setOpen} reset={false} />
        </React.Fragment >
    );
}
