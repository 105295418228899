import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import supportimg from '../img/supportimg.svg'
import { useMediaQuery } from '@mui/material';
import googleAds from '../img/socialimg/Google_Ads_logo.png'
import meta from '../img/socialimg/Meta.png'

export default function Support() {
    const mdScreen = useMediaQuery('(max-width:768px)');
    const smScreen = useMediaQuery('(max-width:600px)');

    const imgSocial = {
        borderRadius: '0',
        backgroundColor: 'transparent',
        objectFit: 'contain',
        width: 'auto',
        height: 'auto',
    }

    return (
        <Box className='portfolio commonBg solution'>
            <Box className='portfolio-container App'>
                <Typography>Strong Reasons to Choose Us? </Typography>
                <Typography variant='span' pt={2}>Unlock Your Potential: Choose Us for Transparent Growth and Unmatched Support</Typography>
            </Box>
            {smScreen && <Box textAlign={'center'}>
                <img style={imgSocial} src={googleAds} alt='social' />
                <img style={imgSocial} src={meta} alt='social' />
            </Box>}
            <Box className={(smScreen ? '' : 'marginTop3') + ' support supportalign'} textAlign={'center'} position={'relative'}>

                <Typography>Authentic
                    Engagement Rate </Typography>
                <Typography>Organic
                    Channel Growth </Typography>

                <Typography>Track
                    Your Growth Easily  </Typography>
                <Typography>Google Ads
                    Customised Dashboard  </Typography>

                <Typography>Growth
                    Report Transparency </Typography>
                <Typography>Dedicated
                    Relationship Manager </Typography>

                <Typography>24*7
                    Customer Support*** </Typography>
                <Typography>Real
                    Time Report   </Typography>
                <img style={{ display: smScreen ? 'none' : 'inline' }} src={supportimg} />
            </Box>

        </Box>
    );
}
