import * as React from 'react';
import './App.css';
import './mediaquery.css';
import Header from './pages/Header'
import Banner from './pages/Banner';
import AdsData from './pages/AdsData';
import Portfolio from './pages/Portfolio';
import Solution from './pages/Solution';
import Campaign from './pages/Campaign';
import Support from './pages/Support';
import Review from './pages/Review';
import Footer from './pages/Footer';
import { Box } from '@mui/material';
import './js/bootstrap.Font.css'
import './js/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.bundle.min';

function App() {
  return (
    <React.Fragment>
      <Box className="container ">
        <Header />
        <Banner />
      </Box>
      <AdsData />
      <Portfolio />
      <Solution />
      <Box className="container ">
        <Campaign />
      </Box>
      <Support />
      <Box className="container ">
        <Review />
      </Box>
      <Footer />
    </React.Fragment>
  );
}

export default App;


